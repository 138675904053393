import React, { Component } from 'react';
import Img from './Img';
import Loading from './Loading';
import PreloadImage from 'react-preload-image';

const API = 'https://admin.longtalljefferson.com/wp-json/wp/v2/';
const QUERY = 'releases?_embed&filter[orderby]=menu_order&order=asc';

class AttachedAlbums extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ isLoading: true });
        fetch(API+QUERY)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
          return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let mainUrl = "/releases/";
        let posts = dataSet.map((post, index) => {
            function fPostHasImage(post, mode) {
                if (post.featured_media > 0){
                    let sourceUrl = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url;
                    let imageAlt = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(post, "sourceUrl");
            let album_type_year = post.acf.album_type_year;
        
            return (
                <div className="post" key={index}>
                    <div className="attachedAlbum">
                        <div className="" id="box1">
                            <a href={mainUrl+post.slug}>
                                <PreloadImage
                                    className="load"
                                    src={imageUrl} 
                                    lazy
                                    duration="1000ms"
                                />
                            </a>
                        </div>
                    </div>
                    <a href={mainUrl+post.slug}>
                        <p className="titleAlbum">{post.title.rendered}<br /> <span>{album_type_year}</span></p>
                    </a>
                </div>
            )
        });
        return (
            <div className="attachedAlbums">
                { posts }
            </div>
        );
    }
}

export default AttachedAlbums;