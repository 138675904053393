import React, { Component } from 'react';
import Img from './Img';

class AttachedLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {dataSet: this.props.data }
    }
    
    componentDidMount() {
        this.setState({ isLoading: true });
    }

    render() {
        const { dataSet } = this.state;
        let images = dataSet.map((image, index) => {
            let imageSrc = image.full_image_url;
            let imageTitle = image.title;
            let url = image.url;
            return (
                <div className="attachedLink" key={index}>
                   <a href={url} target="_blank" rel="noopener noreferrer">
                        <Img src={imageSrc} className="load" alt={imageTitle} />
                    </a>
                </div>
            )
        });
        return (
            <div className="attachedLinks">
                { images }
            </div>
        );
    }
}

export default AttachedLinks;