import React, { Component, Suspense }  from 'react';
import Router from './components/Router';
import { withTranslation } from 'react-i18next';
import Loading from './components/Loading';
import CookieBanner from './components/CookieBanner';
import './App.css';
import './Responsive.css';
 
class App extends Component {

  componentDidMount() {
    window.addEventListener("load", this.setCssVar);
    //if (window.innerWidth >= 600) /* Avoid resize while scrolling on mobile */
    window.addEventListener("resize", this.setCssVar);
  } 

  // SET CSS VARIABLES
  setCssVar() {
    document.documentElement.style.setProperty('--vh', window.innerHeight +"px");
  }

  render() {
    return (
      <div className="App" id="App">
        <CookieBanner />
        <Router />
      </div>
    )
  }
}

const TranslatedComponent = withTranslation()(App);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function Me() {
  return (
    <Suspense fallback={<Loading />}>
      <TranslatedComponent />
    </Suspense>
  );
}
