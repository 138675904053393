import React, { Component } from 'react';
import Footer from '../components/Footer';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';
import Loading from './../components/Loading';
import AttachedImages from './../components/AttachedImages';
import PreloadImage from 'react-preload-image';

const API = 'https://admin.longtalljefferson.com/wp-json/wp/v2/';
const QUERY = 'releases?_embed&filter[orderby]=menu_order&order=asc';

class Release extends Component {

    constructor(props) {
        super(props);
        let pathArray = window.location.pathname.split('/');
        let slug = pathArray[2];
        this.state = { slug: slug, dataSet: [], isLoading: false}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ isLoading: true });
        let addQuery = "";
        if (this.state.slug !== "")
            addQuery = "&slug="+this.state.slug;
        fetch(API+QUERY+addQuery)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
            return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        var exclude;

        //var attachedImages =  dataSet.acf_photo_gallery_album;
        let projects = dataSet.map((project, index) => {
            function fPostHasImage(project, mode) {
                if (project.featured_media > 0){
                    let sourceUrl = project._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
                    let imageAlt = project._embedded['wp:featuredmedia'][0].media_details.sizes.large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(project, "sourceUrl");
            let title = project.title.rendered;
            let content = project.content.rendered;
            let tracklist = project.acf.tracklist;
            let credits = project.acf.credits;
           // let excerpt = project.excerpt.rendered;
            let lang = i18n.language.substr(0,2); 
            let link_spotify = project.acf.link_spotify;
            let link_apple = project.acf.link_apple_music;
            let link_tidal = project.acf.link_tidal;
            let link_amazon = project.acf.link_amazon;
            let link_youtube = project.acf.link_youtube;
            exclude = project.id;
            return (
                <div className="release" key={index} >
                    <Helmet>
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title music')} – {title}</title>
                    </Helmet>
                     <div className="album">
                        <PreloadImage
                            className="loading"
                            src={imageUrl}
                            lazy
                            duration="1000ms"
                        />  
                    </div>
                    <div className="info">
                        {/* <div className="small" dangerouslySetInnerHTML={{__html: excerpt}}></div> */}
                        <h1>{title}</h1>
                        <div className="links">
                            <ul>
                                {link_spotify.length > 0 && <li><a href={'' + link_spotify} target="_blank">Spotify</a></li>}
                                {link_tidal.length > 0 && <li><a href={'' + link_tidal} target="_blank">Tidal</a></li>}
                                {link_apple.length > 0 && <li><a href={'' + link_apple} target="_blank">Apple Music</a></li>}
                                {link_youtube.length > 0 && <li><a href={'' + link_youtube} target="_blank">Youtube</a></li>}
                                {link_amazon.length > 0 && <li><a href={'' + link_amazon} target="_blank">Amazon Music</a></li>}
                            </ul>    
                        </div>  
                        <div className="content">
                            <div className="text">
                            <div className="tracklist" dangerouslySetInnerHTML={{__html: tracklist}}></div>
                            <p className="body" dangerouslySetInnerHTML={{__html: content}}></p>
                            <p className="credits" dangerouslySetInnerHTML={{__html: credits}}></p>
                            </div>
                        </div>
                       
                    </div>             
                </div>
            )
        });
        return (
            <div className="work">
                <div className="projects">
                    { projects }
                    <div className="spacer"></div>
                    {/* <AttachedImages data={attachedImages} /> */}
                </div>
                <Footer />
            </div>
    );
    }
}

export default Release;