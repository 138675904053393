import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import PrivacyPage from '../pages/PrivacyPage';
import ReleasePage from '../pages/ReleasePage';
import ImprintPage from '../pages/ImprintPage';
import i18n from '../i18n';
import { HelmetProvider } from 'react-helmet-async';

class Router extends Component {

    render() {
        return (
            <BrowserRouter>
                <HelmetProvider>
                    <Switch>
                        <Route exact path='/' component={ HomePage }/>
                        <Route path='/privacy' component={ PrivacyPage }/>
                        <Route path='/releases' component={ ReleasePage }/>
                        <Route path='/imprint' component={ ImprintPage }/>
                    </Switch>
                </HelmetProvider>
            </BrowserRouter>
        );
    }
}

export default Router;