import React, { Component } from 'react';
import Loading from './Loading';

//const API = 'https://rest.bandsintown.com/artists/{{Long Tall Jefferson}}/?app_id=yOUrSuP3r3ven7aPp-id';

class Bandsintown extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false }
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://widget.bandsintown.com/main.min.js";
        script.async = true;
        document.body.appendChild(script);
    }
    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <div>
                <div className="events">
                    <a className="bit-widget-initializer" data-artist-name={this.props.data} data-display-local-dates="false" data-display-past-dates="false" data-auto-style="true" data-text-color="#333" data-link-color="#333" data-popup-background-color="#fff" data-background-color="#fff" data-display-limit="" data-link-text-color="#fff"></a>
                </div>
            </div>
        );
    }
}

export default Bandsintown;